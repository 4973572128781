
/**
 * @name: popularity
 * @author: Gzm
 * @date: 2023-07-21 14:55
 * @description：人气推荐
 * @update: 2023-07-21 14:55
 */
import {Vue, Component} from "vue-property-decorator"
import {INotice, INoticeParam} from "@/apis/notice/types"
import {ICrudOption} from "@/types/m-ui-crud"

import {IPurchaseOrder, IPurchaseOrderQuery,} from "@/apis/purchase/types";
import {
  popularityGoodsAddApi, popularityGoodsApi, popularityGoodsExportApi, popularityGoodsQueryApi,
  popularityGoodsRemoveApi,
  popularityGoodsSelectedApi
} from "@/apis/marketing";
import {deepCopy, exportFile} from "@/utils/common";
import {purchaseOrderQueryExportApi} from "@/apis/purchase";

@Component({})
export default class PopularityPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: INotice[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: INoticeParam = {
    page: 1,
    limit: 10
  }
  goodsDialog = false
  titleList = ['商品库', '已选商品']
  goodsWarehouse = [] as any
  fullReduceSelected = [] as any
  fullReduceSelectedIds = [] as any
  // 表单数据
  modelForm: Partial<IPurchaseOrder> = {
    createPurchaseOrderDtlDtoList: []
  }

  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        selectable: this.rowSelectble,
        viewHide: true
      },
      {
        label: "商品ID",
        prop: "goodsId",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true,
      },
      {
        label: "商品",
        prop: "goodName",
        search: true,
        hide:true,
        placeholder:"输入ID/名称模糊搜索"
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
        viewHide: true,
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        type: "switch",
        search: true,
        viewHide: true,
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          },
        ]
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
      },
      {
        label: "商品售价",
        prop: "goodsPrice",
        align: "center",
      },

      {
        label: "商品状态",
        prop: "status",
        addHide: true,
        editHide: true,
        width: 150,
        align: 'center',
        search: true,
        type: "switch",
        viewHide: true,
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          },
        ]
      },
      {
        label: "操作",
        slot: true,
        prop: "btn",
        width: "200",
        align: "center"
      }
    ]
  }
  selectRow: any = []

  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }


  getList() {
    this.tableLoading = true
    popularityGoodsQueryApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total;
      this.tableLoading = false
    })
  }

  rowSelectble(row: any, index: number) {
    return true
  }




  openAdd() {
    this.goodsDialog = true
    this.goodsWarehouse = []
    this.fullReduceSelected = []
    this.fullReduceSelectedIds = []
    popularityGoodsSelectedApi().then(e => {
      this.fullReduceSelected = e.map((item: any) => {
        this.fullReduceSelectedIds.push(item.goodsId)
        return {
          label: item.goodsName,
          key: item.goodsId
        }
      })
      popularityGoodsApi().then(e => {
        this.goodsWarehouse = e.map((item: any) => {
          return {
            label: item.goodsName,
            key: item.goodsId
          }
        })
        this.goodsWarehouse = [...this.goodsWarehouse, ...this.fullReduceSelected]
      })
    })

  }

  /**
   * 删除采购订单
   * @param row
   */
  handleRemove(row: any) {
    this.$confirm('是否确认删除该采购订单?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      popularityGoodsRemoveApi([row.id]).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
    });
  }


  handleRepealAffirm() {
    if (this.fullReduceSelectedIds.length == 0){
      return this.$message.error('请选择数据！')
    }
    popularityGoodsAddApi({
      ids: this.fullReduceSelectedIds
    }).then(e => {
      this.$message.success('操作成功！')
      this.goodsDialog = false
      this.getList()
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    popularityGoodsExportApi(this.queryForm).then(e => {
      exportFile(e, "人气推荐.xlsx")
    })
  }

  /**
   * 批量提交采购订单
   * @param row
   */
  handleSubmitList() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.$confirm('是否确认批量删除人气商品?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      popularityGoodsRemoveApi(this.selectIds).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
    });

  }


  created() {
    this.getList()
  }
}
